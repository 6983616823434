/**
 * Copyright © 2024 Adnuntius AS.
 */
import {Component, Inject, Input} from '@angular/core';
import {interpolateWarning} from "./interpolate-warnings-filter";

import * as _ from 'lodash';

@Component({
  selector: 'validation-warnings',
  template: `
    <div *ngIf="form">
      <div *ngIf="(form.$error && form.$error.validation) || form.bulkError" class="alert alert-danger">
        <p *ngIf="form.$error && form.$error.validation">Your submission had a validation error. Adjust your input according to the details below.</p>
        <p *ngIf="form.bulkError">Your bulk edits had a validation error when applied to one of your selections. Adjust your edit according to the details below or change your selections.</p>
        <ul *ngIf="(form.apiErrors && form.apiErrors.length) || (form.clientErrors && form.clientErrors.length)">
          <li *ngFor="let error of (form.apiErrors || [])">{{error.apiText}}</li>
        </ul>
      </div>
      <!-- Must be on one line with no white space to ensure :empty CSS style works -->
      <div class="alert alert-danger" *ngIf="preventsAdServing().length > 0 && extra"><p>This {{ extra }} cannot deliver until the warnings are resolved.</p></div>
      <div class="alert alert-warning"><p *ngFor="let warning of preventsAdServing()">{{ interpolateWarning(warning) }}</p></div>
      <div class="alert alertSimple"><p *ngFor="let warning of standardWarnings()">{{ interpolateWarning(warning) }}</p></div>
    </div>
  `
})
export class ValidationWarningsComponent {
  @Input() warnings: any;
  @Input() form: any;
  @Input() skips: [string];
  @Input() extra: string;

  constructor(@Inject('$interpolate') private $interpolate) {
  }

  preventsAdServing() {
    const theSkips = this.skips;
    return _.filter(this.warnings, function(w) {
      return w.preventsAdServing && w.code !== 'validation.warning.user.segment.global.missing.team' && !_.includes(theSkips, w.code);
    });
  }

  standardWarnings() {
    const theSkips = this.skips;
    return _.filter(this.warnings, function(w) {
      return !w.preventsAdServing && w.code !== 'validation.warning.user.segment.global.missing.team' && !_.includes(theSkips, w.code);
    });
  }

  interpolateWarning(warning) {
    return interpolateWarning(this.$interpolate, warning);
  }
}