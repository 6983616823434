/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import {SESSION_EVENT} from "../../constants/misc-constants";
import {AUTH_EVENT} from "../../../../components/auth/auth-constants";

import auth from '../../../../components/auth/auth';
import { getRegisteredUrl } from "../../../config/registered-urls";

const MODULE_NAME = "user-profile-theme-directive";

export const VALID_THEMES = ['solarized.dark'];

angular.module(MODULE_NAME, [auth])

  .directive('adnUserTheme', function(localUserProfile, $window, LocalNetworkProfile) {
    return function(scope, element, attrs) {
      let fn = function(e, user) {
        const regUrl = getRegisteredUrl($window, LocalNetworkProfile.get('networkId'));
        const regUrlTheme = _.get(regUrl, 'theme');
        const profileTheme = _.get(user, ['profile', 'adnPrefs', 'theme']) || "";
        const vettedProfileTheme = VALID_THEMES.indexOf(profileTheme) > -1 ? profileTheme : 'solarized.dark';
        attrs.$set('dataUserTheme', regUrlTheme || vettedProfileTheme);
      };
      fn(null, localUserProfile.get());

      scope.$on(AUTH_EVENT.loginSuccess, fn);
      scope.$on(SESSION_EVENT.userProfileUpdate, fn);
    };
  });

export default MODULE_NAME;