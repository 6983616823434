/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import {ApiConfig} from "../api/api";

const MODULE_NAME = "user-paths-helper";

angular.module(MODULE_NAME, [
])

  .factory('userPathsHelper', function($http) {
    return {
      getPaths: function(sessionIds) {
        return $http.get(ApiConfig.obtain().getVersionedUri(['adnpaths', 'userpaths']), {
          params: _.assign({}, {format: 'json'}, {id: _.isArray(sessionIds) ? sessionIds.join(";") : sessionIds})
        }).then(_.iteratee('data'));
      },
      query: function() {
        return $http.get(ApiConfig.obtain().getVersionedUri(['adnpaths']), {
          params: _.assign({}, {format: 'json'})
        }).then(_.iteratee('data'));
      },
      post: function(userPath) {
        return $http.post(ApiConfig.obtain().getVersionedUri(['adnpaths', userPath.userSession.id]), userPath).then(_.iteratee('data'));
      }
    };
  });

export default MODULE_NAME;