/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiCodemirror from 'angular-ui-codemirror';

import standardFormModule from '../../common/standard-form-module';
import listHelper from '../../common/controller/list-helper';
import rateLimitComponent from '../../common/controller/rate-limit-component';
import {adnFormHelper} from "../../common/controller/form-helper";
import targetingTemplate from "../../targeting/base-targeting-editor-modal.html";
import layoutSelectorTemplate from "./layout-selector.html";
import {ADN_TARGETING_TYPES} from "../../targeting/targeting-constants";
import {LocalNetworkInfo} from "../../../components/session/local-network-info";

const MODULE_NAME = 'marketplace-product-controller';

export function allowedDims(theModel) {
  return _.filter(theModel.allowedDimensions, function(dims) {
    return dims[0] >= 0 && dims[1] >= 0;
  });
}

angular.module(MODULE_NAME, [
  uiCodemirror,
  rateLimitComponent,
  standardFormModule,
  listHelper
])

  .controller('MarketplaceProductCtrl', function($stateParams, adnListHelper, LocalUserPermissions, LineItem, Team, LocalNetworkProfile, Layout, $uibModal, model, modelComms) {
    const ctrl = this;

    ctrl.isNew = $stateParams.isNew;

    ctrl.rateLimitTypes = ['RENDERED_IMPRESSION', 'VISIBLE_IMPRESSION', 'VIEWABLE_IMPRESSION', 'BUDGET'];
    if (LocalNetworkProfile.impsOnAxProductRateLimits()) {
      ctrl.rateLimitTypes.unshift("IMPRESSION");
    }
    ctrl.dynamicBiddingAllowed = LocalNetworkProfile.isMarketplacePlatform();

    const defaultSmoothingOption = {id: 'option2', vals: []};
    ctrl.smoothingOptions = [{id: 'option1', vals: ['EVEN', 'FRONT_LOADED']}, defaultSmoothingOption, {id: 'option3', vals: ['FAST_AS_POSSIBLE', 'EVEN', 'FRONT_LOADED']}, {id: 'option4', vals: ['FAST_AS_POSSIBLE', 'EVEN', 'FRONT_LOADED']}, {id: 'option5', vals: ['EVEN']}, {id: 'option6', vals: ['CAPPED']}, {id: 'option7', vals: ['FAST_AS_POSSIBLE']}];
    const afterInitAfterSave = function(data) {
        ctrl.ownership = ctrl.model.ownerTeam ? 'SPECIFIC_TEAM' : 'NETWORK';
        _.set(ctrl.model, 'teams', _.map(_.get(data || ctrl.model, 'teams') || [], 'id'));

      if (!ctrl.isNew && !ctrl.lineItemProducts) {
          ctrl.lineItemProducts = {};
          adnListHelper.setUpBasicList(ctrl.lineItemProducts, LineItem, '', {
            moreParams: {
              axProduct: ctrl.model.id,
              executionState: 'RUNNING',
              concise: true
            }
          });
        }
        ctrl.lineItemColumns = ['name', 'order', 'userState', 'executionState'];
        ctrl.selSmoothingOption = (_.find(ctrl.smoothingOptions, function(option) {
          return option.vals.join(", ") === (ctrl.model.smoothingChoices || []).join(", ");
        }) || {}).id;
      },
      afterInit = function() {
        ctrl.hasLayouts = false;
        if (ctrl.model && ctrl.model.layouts && ctrl.model.layouts.length) {
          Layout.query(_.map(ctrl.model.layouts, 'id'), function(page) {
            ctrl.model.layouts = page.results;
            ctrl.hasLayouts = true;
          });
        } else {
          ctrl.hasLayouts = true;
        }

        ctrl.model.layouts = ctrl.model.layouts || [];

        ctrl.model.type = ctrl.model.type || 'STANDARD';
        ctrl.model.cpmSpecification = ctrl.model.cpmSpecification || 'UNCAPPED';
        ctrl.model.budgetSpecification = ctrl.model.budgetSpecification || 'ALL_OBJECTIVES';
        ctrl.model.lineItemWorkflowType = ctrl.model.lineItemWorkflowType || 'DEFAULT';
        ctrl.model.objectiveSpecification = ctrl.model.objectiveSpecification || 'ANY';
        ctrl.model.bidStrategyOption = ctrl.model.bidStrategyOption || 'STANDARD_ONLY';

        ctrl.targetingResult = {};
        ctrl.creativeTargetingResult = {};

        ctrl.model.allowedDimensions = _.isArray(ctrl.model.allowedDimensions) ? ctrl.model.allowedDimensions : [[]];
        ctrl.model.allowedDimensions[0] = _.isArray(ctrl.model.allowedDimensions[0]) ? ctrl.model.allowedDimensions[0] : [];
      },
      afterSave = function(data) {
        _.set(ctrl, ['targetingResults', 'data'], false);
        _.set(ctrl, ['creativeTargetingResult', 'data'], false);

        ctrl.teamsWarning = (_.get(ctrl.model, 'teams') || []).length !== (_.get(data, 'teams') || []).length;
        ctrl.model.allowedDimensions[0] = _.isArray(ctrl.model.allowedDimensions[0]) ? ctrl.model.allowedDimensions[0] : [];
      };
    ctrl.onBeforeSubmit = function() {
      ctrl.model.allowedDimensions = allowedDims(ctrl.model);
      if (ctrl.ownership === 'NETWORK') {
        ctrl.model.ownerTeam = null;
      }
      if (ctrl.isNew) {
        LocalNetworkInfo.obtain().increment('axProductsCount');
      }
      ctrl.model.smoothingChoices = (_.find(ctrl.smoothingOptions, function(option) {
        return option.id === ctrl.selSmoothingOption;
      }) || defaultSmoothingOption).vals;
    };
    adnFormHelper.setUpForm(ctrl, model, {afterSave: afterSave, afterInit: afterInit, afterInitAfterSave: afterInitAfterSave, modelComms: modelComms});

    ctrl.eventHook = {};
    ctrl.model.targeting = ctrl.model.targeting || {};

    ctrl.targetingTypes = _.map(ADN_TARGETING_TYPES, function(val) {
      return val;
    });

    ctrl.smoothingChoices = ['FAST_AS_POSSIBLE', 'EVEN', 'FRONT_LOADED', 'CAPPED'];

    ctrl.editTarget = function(targeting) {
      const typeTargeting = 'targetingResult';
      const apiParam = 'targeting';
      ctrl[typeTargeting] = {
        data: false
      };
      $uibModal.open({
        template: targetingTemplate,
        controller: 'TargetingCtrl',
        size: 'xl',
        resolve: {
          modelLineItem: function() {
            return angular.copy(ctrl.model.data || {});
          },
          pageType: function() {
            return "marketplaceProduct";
          },
          reachEnabled: function() {
          },
          mandatoryTargets: _.noop,
          availableTargets: function() {
            return null;
          },
          overridableTargeting: function() {
          },
          targetingMode: function() {
            return null;
          },
          targeting: function() {
            return targeting;
          },
          isLockedDown: function() {
            return ctrl.isLockedDown;
          }
        }
      })
        .result.then(function(wc) {
        _.assign(targeting, wc.wc);
        if (!ctrl.isNew) {
          ctrl.model.savePartial(apiParam).then(function(respLineItem) {
            ctrl[typeTargeting].data = true;
            _.set(ctrl.model, 'validationWarnings', respLineItem.validationWarnings);
          });
        }
      });
    };

    ctrl.selectTeams = function() {
      ctrl.editsMade();
      ctrl.model.teams = _.map(ctrl.allTeams, 'id');
    };

    ctrl.deselectTeams = function() {
      ctrl.editsMade();
      ctrl.model.teams = [];
    };

    Team.query({minimal: true, filterBy: 'type', filterByValue: 'AX_ADVERTISER'}).$promise.then(function(page) {
      ctrl.allTeams = page.results;
    });

    Team.query({minimal: true, filterBy: 'type', filterByValue: 'AX_PUBLISHER'}).$promise.then(function(page) {
      ctrl.allPublisherTeams = page.results;
    });

    ctrl.canUpdateProduct = LocalUserPermissions.hasNetwork('MANAGE_SYSTEM') || LocalUserPermissions.hasNetwork('AX_PUBLISHER_ADMIN');
    ctrl.showOwnership = LocalUserPermissions.hasNetwork('MANAGE_SYSTEM');

    const enabledTiers = LocalNetworkProfile.get('tiers'),
      currentTier = ctrl.model.defaultTier;

    ctrl.tiers = angular.copy(enabledTiers);
    if (currentTier) {
      const hasCurrent = !!_.find(ctrl.tiers, function(t) {
        return t.id === _.get(currentTier, 'id');
      });
      // add current tier to list if not in there already -- is probably disabled
      if (!hasCurrent) {
        ctrl.tiers.push(currentTier);
      }
    }
    _.forEach(ctrl.tiers, function(t) {
      t.displayName = t.name + (_.find(enabledTiers, {id: t.id}) ? '' : ' (disabled)');
    });

    ctrl.editLayouts = function() {
      ctrl.layoutResult = {
        data: false
      };
      const modalInstance = $uibModal.open({
        template: layoutSelectorTemplate,
        size: 'lg',
        controllerAs: 'modalCtrl',
        controller:  /*@ngInject*/ function() {
          const modalCtrl = this;

          modalCtrl.selLayouts = _.cloneDeep(ctrl.model.layouts);

          const initSelLayouts = _.map(modalCtrl.selLayouts, (l) => {
            return l.id;
          });

          modalCtrl.select = function(item) {
            item.selected = true;
            modalCtrl.selLayouts.push(item);
          };

          modalCtrl.deselect = function(item) {
            item.selected = false;
            _.remove(modalCtrl.selLayouts, function(l) {
              return item.id === l.id;
            });
          };

          adnListHelper.setUpBasicList(modalCtrl, Layout, '', {
            moreParams: {includeInactive: false, includeHidden: false},
            afterFill: () => {
              _.forEach(modalCtrl.items, (item) => {
                item.selected = initSelLayouts.indexOf(item.id) > -1;
              });
            }
          });

          modalCtrl.saveChanges = function() {
            ctrl.model.layouts = modalCtrl.selLayouts;
            if (!ctrl.isNew) {
              ctrl.model.savePartial('layouts').then(function(respLineItem) {
                ctrl.layoutResult.data = true;
                _.set(ctrl.model, 'validationWarnings', respLineItem.validationWarnings);
                modalInstance.close();
              });
            }
            modalInstance.close();
          };
        }
      });
    };

    ctrl.addDimensions = function() {
      ctrl.model.allowedDimensions = ctrl.model.allowedDimensions || [];
      ctrl.model.allowedDimensions.push([]);
      ctrl.editsMade();
    };
    ctrl.removeDimensions = function(index) {
      _.pullAt(ctrl.model.allowedDimensions, index);
      ctrl.eventHook.removeControlHook('dims' + index + '.width');
      ctrl.eventHook.removeControlHook('dims' + index + '.height');
      if (ctrl.model.allowedDimensions.length < 1) {
        ctrl.model.allowedDimensions[0] = [];
      }
      ctrl.editsMade();
    };

  });

export default MODULE_NAME;
